.guid-popup {
    .dx-overlay-content {
        &.dx-popup-normal {
            width: 60% !important;
            .dx-popup-content {
                width: 100% !important;
                text-align: initial;
                white-space: normal !important;
                -webkit-box-sizing: inherit !important;
                box-sizing: inherit !important;
            }
        }
    }
}
